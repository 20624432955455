<template>
  <main class="m-0-auto pt-20 payment-verification_container">
    <section v-if="transactionStatusData && [1, '1'].includes(transactionStatus) && [0, '0'].includes(transactionVerificationStatus)">
      <h4 class="font-weight-bold text-center">{{ $t("One-time code") }}</h4>
      <p class="text-center">{{ $t("Enter the one-time code given to you in the store") }}</p>
      <div class="mt-20 d-flex flex-column align-items-center">
        <div class="w-100">
          <label for="verification_code">
            {{ $t("Code") }}
          </label>
          <input id="verification_code" class="input text-center" type="tel" v-model="verification_code"
            v-model.lazy="$v.verification_code.$model" :class="{ 'validation-error': $v.verification_code.$error }">
        </div>
        <v-btn color="primary" class="mt-10"  @click="handlePaymentVerificationSubmit">
          {{ $t("Continue") }}
        </v-btn>
      </div>
    </section>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import appConfig from '@/appConfig'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
export default {
  components: {
    Loading
  },
  mixins: [validationMixin],
  name: 'PaymentVerificationPublic',
  data: () => {
    return {
      verification_code: '',
      loading: false
    }
  },
  validations: {
    verification_code: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6)
    }
  },
  computed: {
    ...mapState({
      transactions_statuses: state => state.game_card_payment.transactions_statuses,
      verificationRequestInProgress: state => state.game_card_payment.verificationRequestInProgress,
      checkTransactionStatusInProgress: state => state.game_card_payment.checkTransactionStatusInProgress
    }),
    transactionId() {
      return this.$route.params.transaction_id
    },
    phoneNumber() {
      return this.$route.params.phone_number
    },
    userType() {
      return this.$route.params.user_type
    },
    transactionStatusData() {
      let transactionStatusData = this.transactionId in this.transactions_statuses ? this.transactions_statuses[this.transactionId] : null

      return transactionStatusData
    },
    transactionStatus() {
      let transactionStatus = this.transactionStatusData && this.transactionStatusData.hasOwnProperty('status') ? this.transactionStatusData.status : null

      return transactionStatus
    },
    transactionVerificationStatus() {
      let transactionVerificationStatus = this.transactionStatusData && this.transactionStatusData.hasOwnProperty('verification_status') ? this.transactionStatusData.verification_status : null

      return transactionVerificationStatus ? transactionVerificationStatus : null
    },
    transactionDataStatus() {
      let transactionDataStatus = this.transactionStatusData && this.transactionStatusData.hasOwnProperty('transactionData') && this.transactionStatusData.transactionData.hasOwnProperty('status') ? this.transactionStatusData.transactionData.status : null

      return transactionDataStatus
    },
  },
  created() {
    if (this.transactionVerificationStatus == '1') {
      this.redirectToPaymentResultPublic()
    } else {
      this.checkTransactionStatus({ 'transaction_id': this.transactionId, 'phoneNumber': this.phoneNumber, 'userType': this.userType })
    }
  },
  watch: {
    transactionVerificationStatus(transactionVerificationStatus) {
      if (transactionVerificationStatus == '1') {
        this.redirectToPaymentResultPublic()
      }
    },
    verificationRequestInProgress(inProgress) {
      this.loading = this.verificationRequestInProgress || this.checkTransactionStatusInProgress;
    },
    checkTransactionStatusInProgress(inProgress) {
      this.loading = this.verificationRequestInProgress || this.checkTransactionStatusInProgress;
    }
  },
  methods: {
    ...mapActions('game_card_payment', {
      paymentVerificationRequest: 'paymentVerificationRequest',
      checkTransactionStatus: 'checkTransactionStatus'
    }),
    ...mapActions("alert", {
      errorAlert: "error"
    }),
    getApiURL() {
      return appConfig.apiUrl
    },
    formatNumber(number) {
      let numberFormatObject = new Intl.NumberFormat('en-US')
      return numberFormatObject.format(number)
    },
    handlePaymentVerificationSubmit() {
      let obj = this
      obj.$v.$touch();
      if (obj.$v.$invalid) {
        return;
      }

      const verification_code = obj.verification_code
      const transaction_id = obj.transactionId
      const phone_number = obj.phoneNumber
      const userType = obj.userType
      if (verification_code && transaction_id && phone_number) {
        obj.paymentVerificationRequest({
          supplier_id: null,
          payment_data: {
            transaction_id: transaction_id,
            verification_code: verification_code,
            phoneNumber: phone_number,
            userType: userType
          }
        })
          .then(
            result_data => {
              if (result_data.hasOwnProperty('data') && result_data.data
                && result_data.data.hasOwnProperty('error')
                && result_data.data.error.is_invalid_verification_code) {
                this.errorAlert(this.$t("Incorrect code"));
              } else {
                if (result_data && result_data.hasOwnProperty('currentTransaction') && result_data.currentTransaction
                  && result_data.hasOwnProperty('success') && result_data.success
                  || result_data.hasOwnProperty('status') && result_data.status == 403) {
                  obj.redirectToPaymentResultPublic();
                } else {
                  this.errorAlert(this.$t("Something went wrong, please contact support or try again later"));
                }
              }

            },
            error => {
            }
          );
      }
    },
    redirectToPaymentResultPublic() {
      this.$router.push({name: 'PaymentResultPublic', params: {'transaction_id': this.transactionId, 'user_type': this.userType }})
    }
  },
}
</script>

<style>
.payment-verification_container {
  max-width: 600px;
}
</style>